import React, {useEffect, useState} from "react";
import SearchSelect from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {fetchAttributes, fetchQuestionBanks} from "../../redux/actions/HomeActions";
import axios from "axios";
import {showAlert} from "../../providers/AlertProvider";
import {Button} from "../../components/ui/Button";
import {Card, CardTitle} from "../../components/ui/Card";
import {Select} from "../../components/ui/Select";
import Loading from "../../components/ui/Loading";
import Downloads from "./Downloads";
import {ProgressBar} from "react-bootstrap";
import {Input} from "../../components/ui/Input";

const UploadDatabase = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchQuestionBanks())
        dispatch(fetchAttributes('property'))
    }, []);
    const [databasePath, setDatabasePath] = useState("")
    const {questionBanks, success} = useSelector((state) => state.questionBankListReducer);
    const {attributes} = useSelector((state) => state.attributeListReducer);
    const [questionBank, setQuestionBank] = useState(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [lastResponse, setLastResponse] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialBatchNumber, setInitialBatchNumber] = useState(1);
    const handleInputChange = (property_title_id, property_id) => {
        setSelectedProperties(prevSelectedProperties => {
            const existingIndex = prevSelectedProperties.findIndex(item => item.property_title_id === property_title_id);

            if (existingIndex >= 0) {
                // Update the existing entry with the new property_id
                const updatedProperties = [...prevSelectedProperties];
                updatedProperties[existingIndex] = {
                    ...updatedProperties[existingIndex], property_id: parseInt(property_id)
                };
                return updatedProperties;
            } else {
                // Add a new entry
                return [...prevSelectedProperties, {property_title_id, property_id: parseInt(property_id)}];
            }
        });
    };

    const submitDatabase = async (event) => {
        event.preventDefault();

        // Validate the database path
        if (databasePath === "") {
            showAlert("error", "Please Select Database");
            return;
        } else if (databasePath.slice((databasePath.lastIndexOf(".") - 1 >>> 0) + 2) !== "db") {
            showAlert("error", "Please Select a valid Database");
            return;
        }

        setLoading(true);

        // Initialize batch number and retry count
        let batchNumber = initialBatchNumber;
        let isProcessingComplete = false;
        let retryCount = 0;
        let lastSuccessfulResponse = null;

        // Prepare the initial upload data
        const uploadData = {
            databasePath, questionBankId: questionBank.id, properties: selectedProperties, batch_number: batchNumber, // Send the initial batch number
        };

        try {
            // Loop until all batches are processed
            while (!isProcessingComplete) {
                try {
                    const {data} = await axios.post("/admin/premium/database/add", uploadData);

                    // Update the last successful response
                    lastSuccessfulResponse = data;
                    setLastResponse(data);

                    if (data.next_batch_number) {
                        // Update the batch number for the next request
                        batchNumber = data.next_batch_number + data.table_completed_batches;
                        uploadData.batch_number = batchNumber;
                    } else {
                        // No more batches to process
                        isProcessingComplete = true;
                        showAlert("success", "Database uploaded successfully");
                    }

                    // Reset retry count on successful request
                    retryCount = 0;
                } catch (e) {
                    if (e.message.includes("Network")) {
                        retryCount += 1;

                        if (retryCount <= 3) {
                            showAlert('error', `Retrying (${retryCount}/3) due to heavy load on server`);
                            await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
                        } else {
                            // Give up after 3 retries
                            showAlert('error', "Failed after 3 retries. Here is the last successful response:");
                            console.log("Last successful response:", lastSuccessfulResponse);
                            isProcessingComplete = true;
                        }
                    } else {
                        // Handle other errors
                        showAlert('error', e.message);
                        isProcessingComplete = true;
                    }
                }
            }
        } finally {
            setLoading(false);
        }
    };
    const handleSelectedQuestionBank = (selected) => {
        setSelectedProperties([])
        setQuestionBank(questionBanks.find(x => x.id === Number(selected.value)))
    }
    const progress = lastResponse ? (lastResponse.batch_number / lastResponse.total_batches) * 100 : 0;
    return (success && <form onSubmit={submitDatabase}>
        <Card>
            <CardTitle>Upload SQLite Database</CardTitle>
            <SearchSelect className="mt-1 w-full border border-gray-500"
                          isSearchable={true}
                          options={[...questionBanks.map(x => ({
                              value: x.id, label: x.name
                          }))]}
                          onChange={handleSelectedQuestionBank} // Set the selected option as the new state
                          placeholder={'Question Banks'}/>
            {questionBank?.property_titles.map((field) => (<Select
                id={field.id}
                value={selectedProperties.find(x => x.type_id === field.id)?.value_id}
                onChange={(e) => handleInputChange(field.id, e.target.value)}
                label={field.value}>
                <option value="">Select a Value</option>
                {attributes.map((option) => (<option key={option.id} value={option.id}>
                    {option.name}
                </option>))}
            </Select>))}
            {databasePath !== null && <div>
                Selected Database: {databasePath.name}
            </div>}
            <Downloads setDownloadPath={setDatabasePath}/>
            <Input label={'Initial Batch Number'} type={'number'}
                   value={initialBatchNumber}
                   onChange={(e) => {
                       const value = Number(e.target.value);
                       if (value > 0) {
                           setInitialBatchNumber(value);
                       }
                   }}/>
            {databasePath && <div>Selected Database: {databasePath}</div>}
            {lastResponse && lastResponse.table_type}
            <ProgressBar variant="success" style={{height: "30px"}} animated now={progress.toFixed(2)}
                         label={progress.toFixed(0) + '%'}/>
            <Button disabled={loading} type="submit">{loading ? <Loading small={true}/> : "Upload Database"}</Button>
            {console.log(lastResponse)}
        </Card>
    </form>)
}
export default UploadDatabase