import { useState } from "react";
import axios from "axios";
import ImageUploadComponent from "../../components/ImageUploadComponent";

export default function Announcements() {
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        priority: 1,
        image: null,
        link: "",
        expiry_date: ""
    });
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const setImage = (imageFile) => {
        setFormData({ ...formData, image: imageFile });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage(null);

        const submitData = new FormData();
        submitData.append("title", formData.title);
        submitData.append("description", formData.description);
        submitData.append("priority", formData.priority);
        if (formData.image) submitData.append("image", formData.image);
        submitData.append("link", formData.link);
        if (formData.expiry_date) submitData.append("expiry_date", formData.expiry_date);

        try {
            const response = await axios.post("/admin/general/submit-announcement", submitData, {
                headers: { "Content-Type": "multipart/form-data" }
            });
            setMessage(response.data.success);
        } catch (error) {
            setMessage(error.response?.data?.error || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-md mx-auto p-4 border rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Submit Announcement</h2>
            {message && <p className="mb-2 text-red-500">{message}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formData.title}
                    onChange={handleChange}
                    required
                    className="w-full p-2 border rounded"
                />
                <textarea
                    name="description"
                    placeholder="Description"
                    value={formData.description}
                    onChange={handleChange}
                    required
                    className="w-full p-2 border rounded"
                ></textarea>
                <input
                    type="number"
                    name="priority"
                    placeholder="Priority"
                    value={formData.priority}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                />
                <ImageUploadComponent setImage={setImage} title={"Upload Image"} />
                <input
                    type="text"
                    name="link"
                    placeholder="Link (optional)"
                    value={formData.link}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                />
                <input
                    type="date"
                    name="expiry_date"
                    value={formData.expiry_date}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                />
                <button
                    type="submit"
                    className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    disabled={loading}
                >
                    {loading ? "Submitting..." : "Submit Announcement"}
                </button>
            </form>
        </div>
    );
}
