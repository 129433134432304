import './App.css';
import React, {useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {LoginScreen} from "./pages/Auth/LoginScreen";
import AdminPanelTemplate from "./components/template/AdminPanelTemplate";
import {fetchUserData, refreshUser} from "./redux/actions/UserActions";
import axios from 'axios';
import UpdateQuestionBank from "./pages/Quiz/UpdateQuestionBank";
import {useDispatch, useSelector} from "react-redux";
import UploadDatabase from "./pages/Premium/UploadDatabase";
import AttributesTitles from "./pages/Quiz/AttributesTitles";
import Dashboard from "./pages/Home/Dashboard";
import Attributes from "./pages/Quiz/Attributes";
import UploadFreemiumData from "./pages/Freemium/UploadFreemiumData";
import SubCategoriesOfCategory from "./pages/Quiz/SubCategoriesOfCategory";
import AllQuestionBanks from "./pages/Quiz/AllQuestionBanks";
import DeleteDatabase from "./pages/Premium/DeleteDatabase";
import Colleges from "./pages/Home/Colleges";
import LabValues from "./pages/Home/LabValues";
import Announcements from "./pages/Home/Announcements";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = getBaseUrl();

export function getBaseUrl() {
    const hostname = window.location.hostname
    if (hostname === "localhost") {
        return "http://localhost:8000"
    } else {
        return "https://api.medmolar.com";
    }
}

function App() {
    const dispatch = useDispatch()
    const refreshUserData = useSelector((state) => state.refreshUser);
    const loginUserData = useSelector((state) => state.loginUser);
    useEffect(() => {
        dispatch(refreshUser())
    }, [])
    useEffect(() => {
        refreshUserData.success && dispatch(fetchUserData(refreshUserData.access_token))
    }, [refreshUserData.loading])
    useEffect(() => {
        loginUserData.success && dispatch(fetchUserData(loginUserData.access_token))
    }, [loginUserData.loading])
    const authRoutes = [
        {path: "/", element: <LoginScreen/>},
    ];
    const homeRoutes = [
        {path: "/dashboard", PageContent: Dashboard},
        {path: "/colleges", PageContent: Colleges},
        {path: "/lab-values", PageContent: LabValues},
        {path: "/announcements", PageContent: Announcements},
    ]
    const quizRoutes = [
        {path: "/add-question-bank", PageContent: UpdateQuestionBank},
        {path: "/all-question-banks", PageContent: AllQuestionBanks},
        {path: "/all-question-banks/update/:id", PageContent: UpdateQuestionBank},

        {path: "/property-titles", PageContent: AttributesTitles, props: {typeName: "Property"}},
        {path: "/property-titles/update/:id", PageContent: AttributesTitles, props: {typeName: "Property"}},
        {path: "/properties", PageContent: Attributes, props: {typeName: "Property"}},
        {path: "/properties/update/:id", PageContent: Attributes, props: {typeName: "Property"}},

        {path: "/subcategories-of-categories", PageContent: SubCategoriesOfCategory},

        {path: "/category-titles", PageContent: AttributesTitles, props: {typeName: "Category"}},
        {path: "/category-titles/update/:id", PageContent: AttributesTitles, props: {typeName: "Category"}},
        {path: "/categories", PageContent: Attributes, props: {typeName: "Category"}},
        {path: "/categories/update/:id", PageContent: Attributes, props: {typeName: "Category"}},

        {path: "/subcategory-titles", PageContent: AttributesTitles, props: {typeName: "SubCategory"}},
        {path: "/subcategory-titles/update/:id", PageContent: AttributesTitles, props: {typeName: "SubCategory"}},
        {path: "/subcategories", PageContent: Attributes, props: {typeName: "SubCategory"}},
        {path: "/subcategories/update/:id", PageContent: Attributes, props: {typeName: "SubCategory"}},
    ]
    const premiumRoutes = [
        {path: "/upload-database", PageContent: UploadDatabase},
        {path: "/delete-database", PageContent: DeleteDatabase},
    ]
    const freemiumRoutes = [
        {path: "/upload-data", PageContent: UploadFreemiumData},
    ]
    const renderAdminPanelRoutes = (routes, type) => (
        routes.map(({path, PageContent, props}) => (
            <Route
                key={path}
                path={'/' + type + path}
                element={
                    <AdminPanelTemplate
                        PageContent={props ? <PageContent {...(props || {})}/> : <PageContent/>}
                    />
                }
            />
        ))
    );
    return (
        <BrowserRouter>
            <Routes>
                {authRoutes.map(route => (
                    <Route key={route.path} path={route.path} element={route.element}/>
                ))}
                {renderAdminPanelRoutes(homeRoutes, "home")}
                {renderAdminPanelRoutes(quizRoutes, "quiz")}
                {renderAdminPanelRoutes(premiumRoutes, "premium")}
                {renderAdminPanelRoutes(freemiumRoutes, "freemium")}
                <Route path="*" element={<Navigate replace to="/"/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
