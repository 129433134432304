import React from "react";
import {useDispatch, useSelector} from "react-redux";
import SidebarIcons from "./SidebarIcons";
import PanelSidebarMenu from "./PanelSidebarMenu";
import PanelHeaderMenu from "./PanelHeaderMenu";
import PageHeader from "./PageHeader";
import {hideSidebar} from "../../redux/actions/AdminPanelActions";
import {Navigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";


const sidebarIcons = [{
    id: 0, name: "home", label: "Home", pageList: ["Dashboard", "Colleges", "Lab Values", "Announcements"]
}, {
    id: 1,
    name: "quiz",
    label: "Quiz",
    pageList: ["Property Titles", "Properties", "Category Titles", "Categories", "SubCategory Titles", "SubCategories", "SubCategories of Categories", "Add Question Bank", "All Question Banks",]
}, {
    id: 2, name: "workspace_premium", label: "Premium", pageList: ["Upload Database", "Delete Database"]
}, {
    id: 3, name: "school", label: "Freemium", pageList: ["Upload Data",]
},]
const AdminPanelTemplate = ({PageContent}) => {
    const dispatch = useDispatch()
    const contentClicked = () => {
        dispatch(hideSidebar())
    }

    const sidebar = useSelector((state) => state.sidebar);
    const {user} = useSelector((state) => state.userData);
    const auth = useSelector((state) => state.auth);
    const logoutUserData = useSelector((state) => state.logoutUser);
    const isMobile = useMediaQuery({query: '(max-width: 900px)'});
    if (!auth || logoutUserData.success) {
        return <Navigate to="/"/>
    }
    return (Object.keys(user).length !== 0 && <>
        <div className="flex font-poppins transition-all">
            {/*Sidebar Section*/}
            <div
                className={`fixed ${isMobile ? " w-[80%]" : "w-1/4"} z-40 ${!sidebar ? "-ml-[80%]" : ""} transition-all duration-500`}>
                <div className="flex">
                    <SidebarIcons icons={sidebarIcons}/>
                    <PanelSidebarMenu icons={sidebarIcons}/>
                </div>
            </div>
            {/*Main Section*/}

            <div
                className={`w-full flex flex-col`}>
                <PanelHeaderMenu/>
                <PageHeader icons={sidebarIcons}/>

                {/*Page Content*/}
                {(sidebar) && <div onClick={contentClicked}
                                   className="fixed min-h-screen inset-0 bg-black z-30 opacity-50"></div>}
                <div className="flex flex-col bg-gray-100 gap-4 p-2 pb-5">
                    {PageContent}
                </div>
            </div>
        </div>
    </>)
}
export default AdminPanelTemplate