import React, { useState } from "react";
import { Card, CardTitle } from "../../components/ui/Card";
import { Button } from "../../components/ui/Button";
import Loading from "../../components/ui/Loading";
import axios from "axios";

export default function LabValues() {
    const [labValuesDataFile, setLabValuesDataFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Handle File Selection
    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            if (!file.name.endsWith(".sqlite")) {
                setError("Only .sqlite (SQLite) files are allowed.");
                setLabValuesDataFile(null);
                return;
            }

            setLabValuesDataFile(file);
            setError(null);
        }
    };

    // Upload File to Backend
    const uploadData = async () => {
        if (!labValuesDataFile) {
            setError("Please select a file before uploading.");
            return;
        }

        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append("file", labValuesDataFile);

        try {
            const response = await axios.post("admin/general/lab-values/add/", formData, {
                headers: { "Content-Type": "multipart/form-data" },
            });

        } catch (err) {
            setError("Failed to upload or process the file. Please check the file format.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card className="p-4">
            <CardTitle>Upload Lab Values Database</CardTitle>

            {/* File Upload Area */}
            <div className="my-4">
                <label htmlFor="lab_values_data"
                       className="flex cursor-pointer flex-col justify-center items-center rounded bg-gray-50 border-dashed border-2 py-8 m-2">
                    <span className="material-symbols-rounded text-6xl my-2 text-gray-600">cloud_upload</span>
                    <span className="text-lg text-gray-600">Supported format: .sqlite</span>
                    <span className="text-lg text-gray-600 border-dashed border-gray-500 border-[1px] my-2 mt-3 px-2 py-1 rounded">
                        {labValuesDataFile ? labValuesDataFile.name : "Browse File"}
                    </span>
                </label>
                <input id="lab_values_data" type="file" accept=".sqlite" className="hidden"
                       onChange={handleFileUpload} />
            </div>

            {/* Upload Button */}
            <Button onClick={uploadData} disabled={loading}>
                {loading ? <Loading small={true} /> : "Upload SQLite Database"}
            </Button>

            {/* Error Message */}
            {error && <p className="text-red-500 mt-2">{error}</p>}
        </Card>
    );
}
