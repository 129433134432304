import React from "react";
import {Link, useLocation} from "react-router-dom";
import axios from "axios";
import {useDispatch} from "react-redux";
import {fetchAttributes, fetchQuestionBanks, fetchTitles,} from "../../redux/actions/HomeActions";
import {showAlert} from "../../providers/AlertProvider";

const TableActionComponent = React.forwardRef(({id, tableName}) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const pathname = location.pathname
    const deleteRow = async () => {
        if (tableName.toLowerCase().includes("properties")) {
            try {
                await axios.post('/admin/quiz/property/delete/' + id);
                showAlert("success", "Property Deleted Successfully")
                dispatch(fetchAttributes("property"))
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("property titles")) {
            try {
                await axios.post('/admin/quiz/property-title/delete/' + id);
                showAlert("success", "Property Title Deleted Successfully")
                dispatch(fetchTitles("property"))
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("category title")) {
            try {
                await axios.post('/admin/quiz/category-title/delete/' + id);
                showAlert("success", "Category Title Deleted Successfully")
                dispatch(fetchTitles('category'))
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("subcategory title")) {
            try {
                await axios.post('/admin/quiz/subcategory-title/delete/' + id);
                showAlert("success", "SubCategory Title Deleted Successfully")
                dispatch(fetchTitles('subcategory'))
            } catch (error) {
                showAlert("error", error.message)
            }
        } else if (tableName.toLowerCase().includes("bank")) {
            try {
                await axios.patch(`/admin/quiz/question-bank/delete/${id}/`);
                showAlert("success", "Question Bank Deleted Successfully")
                dispatch(fetchQuestionBanks())
            } catch (error) {
                showAlert("error", error.message)
            }
        }
    }
    const generateEditUrl = () => {
        return `${pathname}/update/${id}`;
    };

    function renderActions() {
        const baseSpan = "material-symbols-rounded cursor-pointer";
        return (<>
            <Link to={generateEditUrl()}> <span className={`${baseSpan} text-green-500 mx-2`}>edit</span></Link>
            <span className={`${baseSpan} text-red-500`} onClick={deleteRow}>delete</span>
        </>)
    }

    return (<>
        {renderActions()}
    </>);
})
export default TableActionComponent